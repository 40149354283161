@font-face {
	font-family: 'BMW Motorrad';
	font-weight: bold;
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Bold.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Bold.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Bold.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Bold.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'BMWMotorradW01-Heavy';
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Heavy.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Heavy.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Heavy.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Heavy.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Heavy.ttf') format('truetype');
}

@font-face {
	font-family: 'BMW Motorrad';
	font-weight: lighter;
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Light.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Light.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Light.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Light.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'BMWMotorradOutlineW01-Rg';
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradOutlineW01-Rg.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradOutlineW01-Rg.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradOutlineW01-Rg.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradOutlineW01-Rg.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradOutlineW01-Rg.ttf') format('truetype');
}

@font-face {
	font-family: 'BMW Motorrad';
	font-weight: normal;
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Regular.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Regular.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Regular.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Regular.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'BMW Motorrad';
	font-weight: bold;
	font-style: italic;
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-BoldItalic.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-BoldItalic.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-BoldItalic.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-BoldItalic.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-BoldItalic.ttf') format('truetype');
}
@font-face {
	font-family: 'BMWMotorradW01-HeavyItalic';
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-HeavyItalic.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-HeavyItalic.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-HeavyItalic.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-HeavyItalic.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-HeavyItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'BMW Motorrad';
	font-weight: normal;
	font-style: italic;
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Italic.eot?#iefix');
	src: url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Italic.eot?#iefix') format('eot'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Italic.woff2') format('woff2'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Italic.woff') format('woff'),
		url('https://bmw-assets-ltnetwork.s3-ap-southeast-1.amazonaws.com/uploads/fonts/BMWMotorradW01-Italic.ttf') format('truetype');
}
