@import '../fonts/fonts';
$primary: #2a64f6;
pre {
	border-radius: 4px;
	background-color: #191c27;
	padding: 16px;
	font-size: 14px;
	margin-bottom: 24px;
}

code[class*='language-'],
pre[class*='language-'] {
	color: rgb(191, 199, 213);
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	direction: ltr;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	tab-size: 4;
	hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
	padding: 1em;
	margin: 0.5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
	background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
	padding: 0.1em;
	border-radius: 0.3em;
}

.token.prolog {
	color: rgb(0, 0, 128);
}

.token.parameter {
	color: rgb(255, 255, 255);
}

.token.comment {
	color: rgb(106, 153, 85);
}

.token.doctype {
	color: rgb(191, 199, 213);
}

.token.cdata {
	color: rgb(191, 199, 213);
}

.token.punctuation {
	color: rgb(136, 198, 190);
}

.token.property {
	color: rgb(252, 146, 158);
}

.token.tag {
	color: rgb(252, 146, 158);
}

.token.class-name {
	color: rgb(250, 200, 99);
}

.token.boolean {
}

.token.constant {
	color: rgb(100, 102, 149);
}

.token.symbol {
	color: rgb(141, 200, 145);
}

.token.deleted {
	color: rgb(141, 200, 145);
}

.token.number {
	color: rgb(181, 206, 168);
}

.token.inserted {
	color: rgb(181, 206, 168);
}

.token.selector {
	color: rgb(215, 186, 125);
}

.token.char {
	color: rgb(209, 105, 105);
}

.token.builtin {
	color: rgb(197, 165, 197);
}

.token.changed {
	color: rgb(197, 165, 197);
}

.token.keyword {
	color: rgb(197, 165, 197);
}

.token.string {
	color: rgb(195, 232, 141);
}

.token.attr-name {
	color: rgb(156, 220, 254);
}

.token.variable {
	color: rgb(156, 220, 254);
}

.token.operator {
	color: #ededed;
}

.token.entity {
	color: #ffffb6;
	cursor: help;
}

.token.url {
	color: #96cbfe;
}

.language-css .token.string,
.style .token.string {
	color: #87c38a;
}

.token.atrule,
.token.attr-value {
	color: #f9ee98;
}

.token.function {
	color: rgb(121, 182, 242);
}

.token.regex {
	color: #e9c062;
}

.token.important {
	color: #fd971f;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.note {
	color: #444;
	padding: 5px 0px;
	font-style: italic;
}

/* 
	FRONT-END Form styling
*/
.lead-form {
	.MuiAccordionSummary-root.Mui-expanded {
		background-color: #3366ff;
		color: #fff;
		min-height: 50px !important;
	}

	.category-details {
		background-color: #f9f9f9;
	}

	.MuiAccordionSummary-root {
		background-color: #000;
		color: #fff;
		min-height: 50px;
		font-size: 20px;
		font-family: 'BMWMotorradW01-Regular';
	}

	.MuiAccordionSummary-root.Mui-expanded {
		background-color: $primary;
		color: #fff;
		min-height: 50px !important;
	}
	.MuiAccordionSummary-expandIcon {
		color: #fff;
	}
	.MuiStepIcon-root.MuiStepIcon-active {
		color: #000;
	}

	.MuiButtonBase-root {
		font-family: 'BMW Motorrad';
		font-weight: bold;
		border-radius: 0;
		border: 0px;
		box-shadow: none;
	}

	.MuiStep-root {
		padding: 10px;
	}
	.MuiStepConnector-vertical {
		margin-left: 22px;
		margin-top: -10px;
	}

	.DayPicker-Weekday,
	.DayPicker-Day {
		background-color: #e0e0e0;
		border-radius: 0;
	}
	.DayPicker-Day,
	.DayPicker-Caption,
	.DayPicker-Weekday {
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		font-weight: lighter;
	}
	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		border-radius: 0;
	}
	.DayPicker-Day--disabled {
		color: #999;
	}
	.thankyou-page {
		.model-block {
			.name {
				text-align: center;
				margin-bottom: 0;
			}
			.MuiDivider-root {
				display: none;
			}
			&:hover {
				.name {
					color: $primary;
					text-align: center;
				}
				.btn-download {
					color: $primary;
				}
			}
		}
	}
}

.dealer-locator-autocomplete {
	background-color: #fff;
	opacity: 1;
	margin-top: 8px;
	.MuiFilledInput-root {
		background-color: #fff;
		opacity: 1;
	}
	.MuiFilledInput-root:hover,
	.MuiFilledInput-root:focus {
		background-color: #fff;
		opacity: 1;
	}
	.MuiFilledInput-input {
		background-color: #fff;
	}
}

.dealership-details {
	pointer-events: all;
	.MuiAccordionSummary-root {
		.MuiAccordionSummary-content {
			margin: 0px 0 0 0;
		}
		.MuiTypography-root {
			font-family: 'BMW Motorrad';
		}
		&.Mui-expanded {
			margin: 5px 0 0 0;
			min-height: auto;
		}
	}
}

.lead-form-details {
	.MuiFilledInput-root {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
	}
	.MuiInputBase-root {
		&:before {
			border-bottom: 0;
		}
		&:hover {
			&:before {
				border-bottom: 0;
			}
		}
		background-color: rgba(0, 0, 0, 0.05);
		.MuiFilledInput-input {
			padding: 10px;
			font-size: 18px;
			font-family: 'BMW Motorrad';
		}
		::placeholder {
			color: rgb(111, 111, 111);
		}
	}
}

.model-block {
	position: relative;
	background-color: #fff;
	padding: 5px;
	padding-bottom: 10px;
	text-align: center;
	.selected-tick {
		display: none;
		position: absolute;
		right: 5px;
	}
	.name {
		font-family: 'BMW Motorrad';
		font-weight: bold;
		text-transform: uppercase;
		margin: 10px;
		text-align: left;
	}
	.btn-download {
		margin: 0px 10px 10px 10px;
		color: #000;
	}
}

.model-block.selected .MuiDivider-root {
	background-color: $primary;
}
.model-block.selected {
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
	.selected-tick {
		display: block;
		transition: linear 0.1s;
		float: right;
	}
}

.price-breakdown {
	padding-bottom: 150px;
	.MuiAccordion-root {
		&.Mui-expanded {
			&:before {
				opacity: 1;
			}
		}
	}
	.MuiAccordionSummary-root {
		flex-direction: row-reverse;
		border: 0;
		.MuiTypography-root {
			font-family: 'BMW Motorrad';
			font-weight: bold;
			color: #333;
		}
		.MuiIconButton-label {
			margin-right: 15px;
			color: #fff;
			padding: 5px;
			background-color: $primary;
		}
		.MuiAccordionSummary-expandIcon.Mui-expanded {
			transform: rotate(0);
		}
		.MuiAccordionSummary-content.Mui-expanded {
			margin: 16px 0;
		}
		&.Mui-expanded {
			min-height: auto;
			.MuiTypography-root {
				color: $primary;
			}
		}
	}
	$borderColor: #ddd;
	.details {
		background-color: #f2f2f2;
		padding: 25px;
		.MuiTable-root {
			border: thin solid $borderColor;
			max-width: 450px;
			font-family: 'BMW Motorrad';
			.MuiTableRow-root {
				border-color: $borderColor;
				.MuiTableCell-root {
					padding: 8px;
					border-color: $borderColor;
					&:first-child {
						border-right: thin solid $borderColor;
					}
				}
			}
		}
	}
}

.bsi-page {
	background-color: #f0f0f0;
	.MuiTypography-h4 {
		font-weight: bold;
		font-family: 'BMW Motorrad';
		text-transform: uppercase;
	}
	.MuiOutlinedInput-root {
		border-radius: 0;
		border: 3px solid #000;
		&.Mui-error {
			border-color: #f44336;
			&.MuiOutlinedInput-input::placeholder {
				color: #f44336;
			}
		}
		.MuiOutlinedInput-input {
			padding: 12px 18px;

			&::placeholder {
				text-transform: uppercase;
			}
			::-webkit-input-placeholder,
			::-moz-placeholder,
			:-ms-input-placeholder,
			:-moz-placeholder {
				/* Chrome/Opera/Safari */
				text-transform: uppercase;
			}
		}
		fieldset {
			border: 0;
		}
	}

	h4.line-through {
		text-align: center;
	}
	h4.line-through span {
		display: inline-block;
		position: relative;
		line-height: 0;
		svg {
			width: 18px;
		}
	}
	h4.line-through span::before,
	h4.line-through span::after {
		position: absolute;
		content: '';
		height: 4px;
		width: 600px;
		border-top: 2px solid;
		top: 9px;
		line-height: 0;
	}
	h4.line-through span::before {
		right: 100%;
		margin-right: 15px;
	}
	h4.line-through span::after {
		left: 100%;
		margin-left: 15px;
	}
}
.MuiButton-outlined.BMW-ButtonOutlined,
a.BMW-ButtonOutlined {
	border-color: #000;
	border-radius: 0;
	border: 3px solid;
	font-weight: bolder;
	font-family: 'BMW Motorrad';
	font-size: 18px;
}

a.BMW-ButtonOutlined {
	padding: 6px 18px;
	display: inline-flex;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
a.BMW-ButtonOutlined.active,
.MuiButton-outlined.BMW-ButtonOutlined.active {
	background-color: #000;
	color: #fff;
	border: 3px solid #000;
}

.MuiTableCell-body.refunded,
.MuiTableCell-body.success {
	color: #51af71;
}
